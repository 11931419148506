
import * as prismicRuntime$zvxs8g2P7j from '/opt/build/repo/node_modules/.pnpm/@nuxt+image@1.8.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.24.0/node_modules/@nuxt/image/dist/runtime/providers/prismic'
import * as imgixRuntime$S7RxMF7PIL from '/opt/build/repo/node_modules/.pnpm/@nuxt+image@1.8.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.24.0/node_modules/@nuxt/image/dist/runtime/providers/imgix'

export const imageOptions = {
  "screens": {
    "xs": 540,
    "sm": 720,
    "md": 960,
    "lg": 1280,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "imgix",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['prismic']: { provider: prismicRuntime$zvxs8g2P7j, defaults: {} },
  ['imgix']: { provider: imgixRuntime$S7RxMF7PIL, defaults: {"baseURL":""} }
}
        